@media screen and (max-width: 749px) {
  .articles-wrapper .article {
    width: 100%;
  }
}

.article {
  display: flex;
  align-items: center;
}

.article.grid__item {
  padding: 0;
}

.grid--peek .article-card {
  box-sizing: border-box;
}

.article-card__image-wrapper > a {
  display: block;
}

.article-card__title {
  text-decoration: none;
  word-break: break-word;
}

.article-card__title a:after {
  bottom: 0;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
}

.article-card__link.link {
  padding: 0;
}

.article-card__link {
  text-underline-offset: 0.3rem;
}

.article-card .card__heading {
  margin-bottom: 0.6rem;
}

.blog-articles .article-card .card__information,
.blog__posts .article-card .card__information {
  padding-left: 2rem;
  padding-right: 2rem;
}

.article-card__info {
  padding-top: 0.4rem;
}

.article-card__footer {
  letter-spacing: 0.1rem;
  font-size: 1.4rem;
}

.article-card__footer:not(:last-child) {
  margin-bottom: 1rem;
}

.article-card__footer:last-child {
  margin-top: auto;
}

.article-card__excerpt {
  width: 100%;
  margin-top: 1.2rem;
}

.article-card__link:not(:only-child) {
  margin-right: 3rem;
}

@media screen and (min-width: 990px) {
  .article-card__link:not(:only-child) {
    margin-right: 4rem;
  }
}

.article-card__image--small .ratio::before {
  padding-bottom: 11rem;
}

.article-card__image--medium .ratio::before {
  padding-bottom: 22rem;
}

.article-card__image--large .ratio::before {
  padding-bottom: 33rem;
}

@media screen and (min-width: 750px) {
  .article-card__image--small .ratio::before {
    padding-bottom: 14.3rem;
  }

  .article-card__image--medium .ratio::before {
    padding-bottom: 21.9rem;
  }

  .article-card__image--large .ratio::before {
    padding-bottom: 27.5rem;
  }
}

@media screen and (min-width: 990px) {
  .article-card__image--small .ratio::before {
    padding-bottom: 17.7rem;
  }

  .article-card__image--medium .ratio::before {
    padding-bottom: 30.7rem;
  }

  .article-card__image--large .ratio::before {
    padding-bottom: 40.7rem;
  }
}

/* check for flexbox gap in older Safari versions */
@supports not (inset: 10px) {
  .articles-wrapper.grid {
    margin: 0 0 5rem 0;
  }

  @media screen and (min-width: 750px) {
    .articles-wrapper.grid {
      margin-bottom: 7rem;
    }
  }
}
